import { useGetAllMethod, usePostMethod } from "@/composables/useApi";
import Fingerprint from "@/composables/useFingerprint";
import isTv from "@/composables/useIsTv";
export const qrStatusPolling = async (options = {}) => {
  return await useGetAllMethod("/api/uc/qr/pull", options);
};

export const getQrCode = (params = {}) => {
  const options = {
    clientCode: isTv() ? "tv" : "h5",
    ...params,
  };
  return useGetAllMethod("/api/uc/qr/url", options);
};

export const qrConfirm = async (params = {}) => {
  const options = { ...params };
  return await useGetAllMethod("/api/uc/qr/confirm", options);
};

export const userLogin = async (params = {}) => {
  //const serial_number = Fingerprint();
  const options = {
    body: {
      //serial_number,
      terminal_name: "PC_9382",
      language_code: localStorage.getItem("adtv-guest-language"),
      model_external_id: "PC",
      ...params,
    },
  };
  return await usePostMethod("/api/uc/user/login", options);
};

export const getStatusOfRegister = (params) => {
  const options = { ...params };
  return useGetAllMethod("/api/uc/manage/register", options);
};

export const getVerifyLink = (params = {}) => {
  const options = { ...params };
  return useGetAllMethod("/api/uc/manage/user/verifyLink", options);
};

export const registerUser = async (params = {}) => {
  const serial_number = Fingerprint();
  const options = {
    body: { ...params, serial_number },
  };
  return await usePostMethod("/api/uc/manage/register", options);
};

export const ssoAuth = async (params = {}) => {
  const options = {
    body: { ...params },
  };
  return await usePostMethod("/api/uc/manage/sso", options);
};

export const ssoLoginUpdate = async (params = {}) => {
  const options = {
    body: { ...params },
  };
  return await usePostMethod("/api/uc/manage/update/household", options);
};

export const resetPassword = async (params = {}) => {
  const options = {
    body: { ...params },
  };
  return await usePostMethod("/api/uc/manage/password/reset", options);
};

export const resendEmail = (params) => {
  const options = { ...params };
  return useGetAllMethod("/api/uc/manage/resend", options);
};

export const validateEmail = (params) => {
  const options = { ...params };
  return useGetAllMethod("/api/uc/manage/account/status", options);
};

export const getUserProfile = (params) => {
  const options = { ...params };
  return useGetAllMethod("/api/uc/user/profile/list", options);
};
